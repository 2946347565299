.navbar {
    background-color: #272727;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
}

.navbar ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 20px;
}

.navbar li {
    font-size: 18px;
}

.navbar a {
    color: white;
    text-decoration: none;
    padding: 8px 12px;
    border-radius: 4px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.navbar a:hover {
    background-color: #272727;
    color: #f0e000;
}

.navbar a.active {
    background-color: #f0e000;
    color: #272727;
}

.logo {
    font-size: 16px;
    color: #f0e000;
    font-family: Lato;
}

.menu-toggle {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.menu-toggle div {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 4px 0;
    transition: all 0.3s ease;
}

.logout-button {
    color: white;
    background-color: #272727;
    text-decoration: none;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 18px;
    border: none;
    cursor: pointer;
}

@media (max-width: 768px) {
    .navbar {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
        flex-wrap: wrap;
    }

    .menu-toggle {
        display: flex;
    }

    .navbar ul {
        flex-direction: column;
        width: 100%;
        display: none;
        gap: 10px;
        margin-top: 15px;
    }

    .navbar ul.active {
        display: flex;
    }

    .navbar li {
        font-size: 16px;
        text-align: center;
        width: 100%;
    }

    .navbar a {
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
    }
}