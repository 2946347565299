.pageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 50px 20px; */
    min-height: 100vh;
    background-image: url("/public/assets/Vip_keltainen.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.card {
    background-color: rgba(255, 255, 255, 0.90);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 40px 60px;
    max-width: 600px;
    text-align: center;
}

.title {
    font-size: 32px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
}

.description {
    font-size: 20px;
    color: #555;
    margin-bottom: 20px;
}

.text {
    font-size: 18px;
    color: #666;
    margin-bottom: 10px;
}

.contactInfo {
    margin: 20px 0;
    font-size: 18px;
    color: #444;
    line-height: 1.5;
}

.subText {
    font-size: 16px;
    color: #888;
    margin-bottom: 10px;
}

.signature {
    margin-top: 30px;
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

@media (max-width: 414px) {
    .pageContainer {
        padding: 30px 10px;
        min-height: auto;
    }

    .card {
        padding: 20px 30px;
        max-width: 90%;
    }

    .title {
        font-size: 24px;
        margin-bottom: 15px;
    }

    .description,
    .text,
    .contactInfo,
    .subText,
    .signature {
        font-size: 16px;
    }
}