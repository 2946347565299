/* body {
    font-family: Arial, sans-serif;
    background-color: #f4f6f9;
    margin: 0;
    padding: 0;
} */

.receiptForm {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 400px;
    margin: 20px auto;
    text-align: center;
}

.receiptForm h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333333;
    text-align: center;
}

.receiptForm input[type="text"],
.receiptForm input[type="email"],
.receiptForm input[type="number"],
.receiptForm input[type="date"],
.receiptForm input[type="time"] {
    width: calc(100% - 22px);
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    font-size: 14px;
    color: #333333;
    outline: none;
    transition: border-color 0.3s ease;
}

.receiptForm input[type="text"]:focus,
.receiptForm input[type="email"]:focus,
.receiptForm input[type="number"]:focus,
.receiptForm input[type="date"]:focus,
.receiptForm input[type="time"]:focus {
    border-color: #007bff;
}

.receiptForm label {
    margin-bottom: 5px;
    font-size: 14px;
    color: #555555;
}

.receiptForm input[type="radio"] {
    margin-right: 5px;
}

.receiptForm label.radio-label {
    margin-right: 15px;
    font-size: 14px;
    color: #333333;
}

.receiptForm button[type="submit"] {
    width: 100%;
    background-color: #007bff;
    color: #ffffff;
    padding: 12px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    outline: none;
}

.receiptForm button[type="submit"]:hover {
    background-color: #0056b3;
}

.receiptForm .payment-method {
    margin-bottom: 20px;
}

.receiptForm .payment-method label {
    display: flex;
    align-items: center;
}

.receiptForm div {
    margin-bottom: 20px;
}

.loaderOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}


.loaderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 200px;
    height: 150px;
}

.loaderContainer p {
    margin-top: 20px;
    font-size: 16px;
    color: #333;
    text-align: center;
}

@media (max-width: 500px) {
    .receiptForm {
        width: 90%;
    }

    .receiptForm input[type="text"],
    .receiptForm input[type="email"],
    .receiptForm input[type="number"],
    .receiptForm input[type="date"],
    .receiptForm input[type="time"] {
        width: calc(100% - 12px);
    }

    .receiptForm button[type="submit"] {
        padding: 10px;
    }
}