.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.login-form {
    background-color: #f9f9f9;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
}

.login-input {
    width: 100%;
    padding: 12px;
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
}

.login-button {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    margin-top: 16px;
    transition: background-color 0.3s ease;
}

.login-button:hover {
    background-color: #0056b3;
}

@media (max-width: 600px) {
    .login-form {
        padding: 1.5rem;
        width: 90%;
    }

    .login-input {
        font-size: 14px;
        padding: 10px;
    }

    .login-button {
        font-size: 14px;
        padding: 10px;
    }
}